import { useEffect, useState } from 'react';
import {
  Case,
  Product,
  Storeroom,
  Todo,
  scannedProduct,
} from '@lib/common/type';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import StockAllocationMainTable from '@components/modules/pc/stock_allocation_status/StockAllocationMainTable/StockAllocationMainTable';
import StockAllocationEditModal from '@components/modules/pc/stock_allocation_status/StockAllocationEditModal/StockAllocationEditModal';
import {
  StockAllocationEditData,
  initEditData,
} from '@lib/pc/stock_allocation/type';
import userCreateStockAllocationMutation from '@lib/pc/stock_allocation/userCreateStockAllocationMutation';
import useUpdateStockAllocationMutation from '@lib/pc/stock_allocation/useUpdateStockAllocationMutation';
import useDeleteStockAllocationMutation from '@lib/pc/stock_allocation/useDeleteStockAllocationMutation';
import scanSound from '@assets/sounds/sound.mp3';
import BarcodeReader from '@components/modules/pc/BarcodeScanner/BarcodeReader';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';

type Props = {
  stockAllocations: Todo;
  selectedStoreroomId: number | undefined;
  date: Date;
  selectedStoreroomsProducts: Product[];
  storerooms: Storeroom[];
  cases: Case[];
  setPopUp: (popUp: boolean) => void;
  setEditProductName: (editProductName: string) => void;
  refetch: () => void;
  setMessageKind: (messageKind: string) => void;
  casesPerCart: string;
  setCasesPerCart: (casesPerCart: string) => void;
};

export type InputParams = {
  time: string;
};

const defaultScanProduct = {
  productId: null,
  productName: '',
  productCode: '',
};

const StockAllocationContents = ({
  stockAllocations,
  selectedStoreroomId,
  date,
  selectedStoreroomsProducts,
  storerooms,
  cases,
  setPopUp,
  setEditProductName,
  refetch,
  setMessageKind,
  casesPerCart,
  setCasesPerCart,
}: Props) => {
  const [editData, setEditData] =
    useState<StockAllocationEditData>(initEditData);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | ''>('');
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [audio] = useState(new Audio(scanSound));
  const [errMsg, setErrMsg] = useState('');
  const [foundScannedProduct, setFoundScannedProduct] = useState(false);
  const [scannedProduct, setScannedProduct] =
    useState<scannedProduct>(defaultScanProduct);
  const handleDelete = () => {
    deleteStockAllocationMutation.mutate();
    setEditData(initEditData);
  };

  // 最新データの取得
  const getLatestData = () => {
    refetch();
  };

  const handleDeleteSuccess = () => {
    setDeleteId('');
    setEditModalOpen(false);
    setPopUp(true);
    setMessageKind('delete');
    getLatestData();
  };

  const handleCreateSuccess = () => {
    setPopUp(true);
    setMessageKind('create');
    getLatestData();
    setEditData(initEditData);
    setEditModalOpen(false);
  };

  const handleUpdateSuccess = () => {
    setPopUp(true);
    setMessageKind('update');
    getLatestData();
    setEditData(initEditData);
    setEditModalOpen(false);
  };

  const handleClose = () => {
    setEditModalOpen(false);
    setScanning(true);
    setResults([]);
    setScannedProduct(defaultScanProduct);
  };

  const handleSubmit = (newEditData: StockAllocationEditData) => {
    if (newEditData.detailId === 0) {
      createStockAllocationMutation.mutate(newEditData);
    } else {
      updateProductProductionResultMutation.mutate(newEditData);
    }
    setScanning(true);
    setResults([]);
    setScannedProduct(defaultScanProduct);
  };

  // 払出入力データ作成処理
  const createStockAllocationMutation = userCreateStockAllocationMutation(
    // PRODUCT_KIND.PRODUCT,
    editData?.productId,
    date,
    selectedStoreroomId ? selectedStoreroomId : storerooms?.[0]?.id,
    handleCreateSuccess
  );

  // 払出入力データ更新処理
  const updateProductProductionResultMutation =
    useUpdateStockAllocationMutation(editData?.detailId, handleUpdateSuccess);

  // 払出入力データ削除
  const deleteStockAllocationMutation = useDeleteStockAllocationMutation(
    editData?.detailId,
    handleDeleteSuccess
  );

  useEffect(() => {
    if (results.length !== 0) {
      const getData = selectedStoreroomsProducts.filter(
        (storeroomsProducts: Todo) => {
          (storeroomsProducts.janCode === results[0].codeResult.code ||
            storeroomsProducts.productCode === results[0].codeResult.code) &&
            storeroomsProducts.storeroomIds.includes(
              Number(selectedStoreroomId)
            );
          return (
            (storeroomsProducts.janCode === results[0].codeResult.code ||
              storeroomsProducts.productCode === results[0].codeResult.code) &&
            storeroomsProducts.storeroomIds.includes(
              Number(selectedStoreroomId)
            )
          );
        }
      );

      const scannedProductInfo = {
        productId: getData[0] ? getData[0].id : null,
        productCode: getData[0] ? getData[0].productCode : '',
        productName: getData[0] ? getData[0].name : '',
      };
      setScannedProduct(scannedProductInfo);

      if (!!getData && getData.length > 0) {
        setEditData({
          ...initEditData,
        });
        setPopUp(false);
        setEditModalOpen(true);
        setFoundScannedProduct(true);
      } else {
        setErrMsg('該当の商品が見つかりませんでした。');
        setFoundScannedProduct(false);
        setEditModalOpen(false);
      }
    }
  }, [results]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <StockAllocationMainTable
          data={stockAllocations}
          setEditData={setEditData}
          setPopUp={setPopUp}
          setEditModalOpen={setEditModalOpen}
          setScanning={setScanning}
        />
      </DndProvider>
      {editModalOpen && (
        <StockAllocationEditModal
          scannedProduct={scannedProduct}
          editModalOpen={editModalOpen}
          data={editData}
          selectedStoreroomsProducts={selectedStoreroomsProducts}
          storerooms={storerooms}
          cases={cases}
          selectedStoreroomId={selectedStoreroomId}
          handleClose={() => handleClose()}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          setEditProductName={setEditProductName}
          casesPerCart={casesPerCart}
          setCasesPerCart={setCasesPerCart}
          hasCase={stockAllocations?.hasCase}
          setEditData={setEditData}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
      )}
      {editModalOpen && <Overlay dark />}
      {scanning && (
        <BarcodeReader
          onDetected={(result: Todo) => {
            setScanning(false);
            audio.play();
            const newResult = {
              codeResult: { code: result.codeResult.code },
              inputText,
            };
            setResults([...results, newResult]);
          }}
        />
      )}
      {!foundScannedProduct && (
        <ErrorMsgPopUp
          fromPc={true}
          errMsg={errMsg}
          handleClose={() => {
            setResults([]);
            setScanning(true);
            setErrMsg('');
          }}
        />
      )}
    </div>
  );
};

export default StockAllocationContents;
