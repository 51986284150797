import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './MaterialInventory.styles';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import DateSelector from '@components/elements/inputs/DateSelector/DateSelector';
import { WithHeader } from '@components/templates/WithHeader';
import AllStoreroomsRightSlideSidebar from '@components/modules/pc/materials_inventory/AllStoreroomsRightSlideSidebar/AllStoreroomsRightSlideSidebar';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import RightSlideSidebar from '@components/modules/pc/materials_inventory/RightSlideSidebar/RightSlideSidebar';
import StoreroomSelect from '@components/modules/pc/stock_status/StoreroomSelect/StoreroomSelect';
import TableWithFixedSidebar from '@components/modules/pc/materials_inventory/TableWithFixedSidebar/TableWithFixedSidebar';
import ProductDetailModal from '@components/modules/pc/materials_inventory/ProductDetailModal/ProductDetailModal';
import useStorerooms from '@lib/pc/common/hooks/useStorerooms';
import {
  MATERIAL_PRODUCT_KIND,
  type Storeroom,
  type Todo,
} from '@lib/common/type';
import type {
  selectedEstimatedMaterial,
  selectedItem,
  DetailItem,
} from '@lib/pc/materials_inventory/type';
import { PCPath } from '@config/path';

export const MaterialInventoryRoot = () => {
  const initializeSelectedItem = {
    materialId: 0,
    materialName: '',
    materialType: '',
    type: '',
    date: '',
    amountDetail: [],
    comment: '',
    piecesPerBox: 1,
    piecesPerUnit: 1,
    labelOfAmount: '',
    labelOfUnit: '',
    actualStocks: [],
    estimatedStocks: [],
    actualDeliveries: [],
    estimatedDeliveries: [],
  };

  const initializeEstimatedMaterial = {
    materialName: '',
    type: '',
    materialId: 0,
    date: '',
  };

  const today = new Date();
  const [date, setDate] = useState(new Date());
  const [initialize] = useState<Todo>(initializeSelectedItem);
  const [selectedItem, setSelectedItem] = useState<selectedItem>(initialize);
  const [selectedEditItem, setSelectedEditItem] =
    useState<selectedItem>(initialize);
  const [
    selectedSelectedEstimatedMaterial,
    setSelectedSelectedEstimatedMaterial,
  ] = useState<selectedEstimatedMaterial>(initializeEstimatedMaterial);
  const [popUp, setPopUp] = useState(false);
  const [rightSlideSidebarActive, setRightSlideSidebarActive] = useState(false);
  const [allRightSlideSidebarActive, setAllRightSlideSidebarActive] =
    useState(false);
  const [productDetailModalActive, setProductDetailModalActive] =
    useState(false);
  const [selectedStoreroomId, setSelectedStoreroomId] = useState('0');
  const [errMsg, setErrMsg] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [messageKind, setMessageKind] = useState('');

  const [inputAmountParams, setInputAmountParams] = useState<Todo>([]);
  const [comment, setComment] = useState('');
  // SidebarInputの入力フォーム（日付、ケース、バラ）、備考への変更有無
  const [isChanged, setIsChanged] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');

  const formattedDate = selectedEditItem
    ? moment(selectedEditItem.date).format('YYYY/MM/DD (ddd)')
    : '';
  const productName =
    selectedEditItem && selectedEditItem.materialName
      ? selectedEditItem.materialName
      : '';

  const { data: storerooms } = useStorerooms(MATERIAL_PRODUCT_KIND.MATERIAL);

  const sortedStoreRoom = storerooms?.sort(
    (a: Storeroom, b: Storeroom) => a.id - b.id
  );

  const [initializeAmount, setInitializeAmount] = useState<DetailItem>({
    amountDetailId: '',
    piece: '',
    case: '',
    total: 0,
    piecesPerBox: 1,
    expirationDate: '',
  });

  const handleOpenProductDetail = useCallback((item: Todo) => {
    setSelectedItem(item);
    setProductDetailModalActive(!productDetailModalActive);
  }, []);

  const handleSelectedMaterial = async (item: Todo) => {
    if (selectedStoreroomId !== '0') {
      setSelectedEditItem(item);
      setInitializeAmount(initializeAmount);
      setRightSlideSidebarActive(!rightSlideSidebarActive);
      setPopUp(false);
    }
  };

  const handleSelectEstimatedMaterial = (item: Todo) => {
    setSelectedSelectedEstimatedMaterial(item);
    setAllRightSlideSidebarActive(!allRightSlideSidebarActive);
  };

  const handleClosePopUp = () => {
    setPopUp(false);
  };

  const onClickCancel = () => {
    if (isChanged) {
      setPopUp(false);
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
    } else {
      setInputAmountParams([]);
      setRightSlideSidebarActive(false);
      setPopUp(false);
      setComment('');
    }
  };

  // 1日戻る
  const getBeforeDate = (date: Date) => {
    setDate(moment(date).subtract(1, 'days').toDate());
    setPopUp(false);
  };

  // 1日進む
  const getNextDate = (date: Date) => {
    // 未来日も選択可能
    setDate(moment(date).add(1, 'days').toDate());
    setPopUp(false);
  };

  const handleClickToday = () => {
    setDate(today);
  };

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.materialInventory}`);
  }, []);

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <div>
            <Heading tag="span" text="資材・原材料在庫状況" fs="32" fw="lg" />
            <Heading tag="span" text="を確認" fs="24" fw="lg" />
          </div>
          <S.SelectContainer>
            <S.DisplayOptionWrapper>
              <S.StoreroomSelectWrapper>
                <StoreroomSelect
                  storerooms={sortedStoreRoom}
                  value={Number(selectedStoreroomId)}
                  setSelectedStoreroomId={setSelectedStoreroomId}
                  rightSlideSidebarActive={rightSlideSidebarActive}
                />
              </S.StoreroomSelectWrapper>
              <S.DateWrapper onClick={() => setPopUp(false)}>
                <Button
                  width="90px"
                  margin={'0 8px 0 0'}
                  outlined={true}
                  borderWidth={1}
                  onClick={handleClickToday}
                >
                  今日
                </Button>
                <DateSelector
                  date={date}
                  getBeforeDate={getBeforeDate}
                  getNextDate={getNextDate}
                  setDate={setDate}
                  capableSelectFutureDate
                />
              </S.DateWrapper>
            </S.DisplayOptionWrapper>
          </S.SelectContainer>
        </S.HeadContainer>

        <div>
          <TableWithFixedSidebar
            selectedDate={moment(date)}
            selected={false}
            handleSelectedMaterial={handleSelectedMaterial}
            handleSelectEstimatedMaterial={handleSelectEstimatedMaterial}
            handleOpenProductDetail={handleOpenProductDetail}
            rightSlideSidebarActive={rightSlideSidebarActive}
            onClickCancel={onClickCancel}
            selectedStoreroomId={Number(selectedStoreroomId)}
          />
          {allRightSlideSidebarActive && (
            <AllStoreroomsRightSlideSidebar
              rightSlideSidebarActive={allRightSlideSidebarActive}
              setRightSlideSidebarActive={setAllRightSlideSidebarActive}
              selectedMaterial={selectedSelectedEstimatedMaterial}
              selectedStoreroomId={selectedStoreroomId}
            />
          )}
          {rightSlideSidebarActive && (
            <RightSlideSidebar
              selectedDate={moment(date)}
              rightSlideSidebarActive={rightSlideSidebarActive}
              setRightSlideSidebarActive={setRightSlideSidebarActive}
              selectedMaterial={selectedEditItem}
              selectedStoreroomId={selectedStoreroomId.toString()}
              popUp={popUp}
              setPopUp={setPopUp}
              setIsDelete={setIsDelete}
              setMessageKind={setMessageKind}
              inputAmountParams={inputAmountParams}
              setInputAmountParams={setInputAmountParams}
              comment={comment}
              setComment={setComment}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              confirmMsg={confirmMsg}
              setConfirmMsg={setConfirmMsg}
              onClickCancel={onClickCancel}
            />
          )}
        </div>
      </S.Wrapper>
      <ProductDetailModal
        productDetailModalActive={productDetailModalActive}
        selectedDate={moment(date)}
        data={selectedItem}
        handleClose={() => {
          setProductDetailModalActive(false);
          setSelectedItem(initialize);
        }}
      />
      <UpdatePopUp
        popUp={popUp}
        formattedDate={formattedDate}
        productName={productName}
        handleClose={handleClosePopUp}
        fromPc={true}
        taskKind={'stock'}
        messageKind={messageKind}
        isDelete={isDelete}
      />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        fromPc={true}
      />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </WithHeader>
  );
};
