import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './SemiProductMaster.styles';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { WithHeader } from '@components/templates/WithHeader';
import TableWithFilter from '@components/modules/pc/settings/semi_product_master_setting/TableWithFilter/TableWithFilter';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import { Storeroom, Tag, Todo, PRODUCT_KIND } from '@lib/common/type';
import { ProductMasterSettingState } from '@lib/pc/settings/product_master_setting/type';
import useSemiProducts from '@lib/pc/settings/product_master/useSemiProducts';
import { PCPath } from '@config/path';

export const SemiProductMasterSettingRoot = () => {
  const { state } = useLocation<ProductMasterSettingState>();
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  // 一覧画面で検索のために選ばれたタグ
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [selectedStorerooms] = useState<Storeroom[]>([]);
  // 編集データの設定
  const [editData, setEditData] = useState<Todo>(null);
  const { semiProducts: semiProducts, refetch } = useSemiProducts();
  const [messageKind, setMessageKind] = useState('');
  const [productName, setProductName] = useState('');
  const [popUp, setPopUp] = useState(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  // 編集画面で保存ボタン押下後、refethcのため
  const handleSuccessEditSubmit = () => {
    refetch();
  };

  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('table-contents')?.scrollTo(0, 0);
      setSearchWord('');
      setSelectedTags([]);
    } else {
      setSearchWord(state.stateSearchWord);
      setSelectedTags(state.stateSelectedTags);
      document
        ?.getElementById('table-contents')
        ?.scrollTo(0, state.stateScrollPosition);
    }
    refetch();
  }, [state]);

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTags?.length === 0]);

  useEffect(() => {
    localStorage.setItem(
      'last_view_page',
      `${PCPath.settingsSemiProductMaster}`
    );
  }, []);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <div>
              <S.ProductKind>半製品</S.ProductKind>
              <Heading tag="span" text="製品マスタ管理" fs="32" fw="lg" />
            </div>

            {/* 新規登録ボタン */}
            <S.ButtonWrapper>
              <S.ButtonContainer>
                <Link
                  to={{
                    pathname: `/pc/settings/semi_product_master_setting_create`,
                    state: {
                      ...state,
                      stateSearchWord: '',
                      stateSelectedTags: [],
                      editData: null,
                    },
                  }}
                  style={{ position: 'absolute' }}
                >
                  ＋ 新規登録
                </Link>
              </S.ButtonContainer>
            </S.ButtonWrapper>
          </S.HeadContainer>
        </div>

        <TableWithFilter
          products={semiProducts}
          refetch={refetch}
          editData={editData}
          setEditData={setEditData}
          popUp={popUp}
          setPopUp={setPopUp}
          messageKind={messageKind}
          setMessageKind={setMessageKind}
          productName={productName}
          setProductName={setProductName}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          selectedStorerooms={selectedStorerooms}
          handleSuccessEditSubmit={handleSuccessEditSubmit}
          tagMenuActive={tagMenuActive}
          setTagMenuActive={setTagMenuActive}
        />
      </S.Wrapper>
      {/* タグ＆検索画面 */}
      {tagMenuActive && (
        <TagMenu
          top={'190px'}
          left={'350px'}
          title={'半製品'}
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {/* タグ検索画面からの選択画面 */}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
    </WithHeader>
  );
};
